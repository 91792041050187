import React from "react";
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import './ClientView.css';
import Row from 'react-bootstrap/Row';
import { useEffect, useState } from "react";
import axios from 'axios';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/autoplay";
import { Autoplay, FreeMode } from "swiper/modules";
import { Helmet } from "react-helmet-async";

// import $ from 'jquery';




function ClientView() {



    const [clientlogos, setData] = useState([]);

    useEffect(() => {
        axios.get("https://megamindonline.com/admin/webmanager/controller.php?command=GET_HOME_DATA_AA")
            .then((response) => {
                setData(response.data.split(";").filter(r => r !== ''));
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);
    console.log(clientlogos)

    return (
        <>
            <Helmet>
                <script async defer crossorigin="anonymous" src="https://www-cdn.icef.com/scripts/iasbadgeid.js"></script>
            </Helmet>
            <Container style={{ background: "" }} fluid>
                <Row className="pb-5 border-bottom" style={{ width: "80%", margin: "auto" }}>
                    <Col sm={12} md={12} className="py-5 text-center">
                        <h2 className="h-one text-black" style={{ letterSpacing: "-1px" }}> Accreditation & Association</h2>
                    </Col>
                    <div className="slider-container">
                        <Swiper
                            modules={[Autoplay, FreeMode]}
                            spaceBetween={30}
                            slidesPerView="auto"
                            loop={true}

                            autoplay={{
                                delay: 0, // No delay, continuous movement
                                disableOnInteraction: false, // Prevents stopping on interaction
                            }}
                            speed={2000} // Adjust speed for smooth effect
                            className="swiper-container"
                        >
                            {/* Duplicate logos to ensure a seamless loop */}
                            {[...clientlogos, ...clientlogos].map((clientlogo, index) => (
                                <SwiperSlide key={index} className="slide">
                                    <img src={clientlogo ? clientlogo : 'https://placehold.co/40x40'} alt="logo-img" />
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>

                    <div className="container p-5 d-flex justify-content-center">
                        <span className="ias-badge" id='iasBadge' data-account-id='3573'></span>
                    </div>

                </Row>

            </Container>


        </>
    )
}

export default ClientView;


